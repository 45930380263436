.results {
  position: absolute;
  background: #f8f8f8;
  width: 19%;
  margin-top: 5px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  z-index: 500;
  padding: 10px;
  border-radius: 10px;
  overflow-y: scroll;
  height: 440px;
}
/* width */
.results::-webkit-scrollbar {
  width: 8px;
  display: none;
}

/* Track */
.results::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.results::-webkit-scrollbar-thumb {
  background: rgb(207, 195, 195);
  border-radius: 10px;
}
.top-bar {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  background-color: #f5f5f5;
}
.top-bar::-webkit-scrollbar{
  display: none;
}
.sdg-options {
  display: flex;
  gap: 10px;
}

.sdg-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  min-width: 150px;
}

.sdg-button.active {
  background-color: #e0f7fa;
}

.sdg-button .sdg-image {
  width:90px;
  height: 90px;
}
.sdg-button:nth-child(1) .sdg-image{
  width: 40px;
}
.icon {
  width: 0px;
}

.img img {
  width: 100px;
  margin-left: -45px;
  border-radius: 50%;
}
