.sdgContainer {
  max-width: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  /* height: 100%; */
  /* margin: 0 auto; */
  /* align-content: center; */
  /* padding: 20px; */
  /* padding-top: 250px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  /* background-color: #f9f9f9; */
}

.sdgContainer h2 {
  text-align: center;
}

.sdgOptions {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.sdgOption {
  display: flex;
  align-items: center;
}

.sdgLabel {
  margin-left: 10px;
}

.customCheckbox {
  position: relative;
}

.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.customCheckbox label {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ccc;
  display: inline-block;
  border-radius: 50%;
}

.customCheckbox input:checked + label {
  background-color: #4caf50;
  border-color: #4caf50;
}

.customCheckbox input:checked + label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.selectedSdgsContainer {
  position: relative;
  margin-top: 20px;
}

.selectedSdgs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
}

.submitButton {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submitButton:hover {
  background-color: darkorange;
}

.errorMessage {
  color: red;
  font-size: large;
  text-align: center;
  margin-top: 60px;
}

.selectedSdg {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: green;
  color: white;
  border-radius: 20px;
}

.selectedSdg span {
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
}
