.postPage {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.postHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.authorImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.authorInfo h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.authorInfo p {
  margin: 0;
  font-size: 14px;
  color: gray;
}

.postTitle {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.postImage {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.postContent {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.postContent p {
  margin-bottom: 20px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader span {
  width: 20px;
  height: 20px;
  background: #333;
  margin: 5px;
  border-radius: 50%;
  animation: loader 0.6s infinite alternate;
}

@keyframes loader {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}

.commentsSection {
  margin-top: 40px;
}

.commentForm {
  display: flex;
  flex-direction: column;
}

.commentInput {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.commentButton {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.commentButton:hover {
  background-color: #0056b3;
}

.commentsList {
  margin-top: 20px;
}

.comment {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.comment p {
  margin: 0 0 5px;
}

.comment small {
  color: #666;
}
