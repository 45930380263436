.flex {
  position: absolute;
  height: 115vh;
  width: 98.95vw;
  z-index: 20;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
}
